import React from 'react';
import { format } from 'date-fns';
import { Link } from 'gatsby';

import Layout from '../layouts';

import SEO from '../components/_atoms/SEO/SEO';

import './blog-post.scss';

const bloggers = {
    nick: {
        name: 'Nick McBlain',
        img: 'https://pbs.twimg.com/profile_images/928562132848914432/tDncnHkA_400x400.jpg',
        role: 'CTO'
    },
    beth: {
        name: 'Beth Cleavy',
        img: 'https://s3-eu-west-1.amazonaws.com/io.orton.images/58a4ab963306960011a4f50b.jpg',
        role: 'CEO & Founder'
    },
    cheryl: {
        name: 'Cheryl Bryne',
        img: 'https://pbs.twimg.com/profile_images/1094140541481242625/mX1arWen.jpg',
        role: 'Head of Community'
    }
};

const Blogger = ({ name }) => (
    <div className="blogger">
        <img src={bloggers[name].img} alt="Blogger's name" />
        <div>
            <h5>{bloggers[name].name}</h5>
            <p>{bloggers[name].role}</p>
        </div>
    </div>
);

const renderContentType = (type, index) => {
    switch (type.__typename) {
        case 'PrismicBlogPostBodyContent':
            let content = [];
            type.items.forEach((item) => {
                if (item.subheading.text) {
                    content.push(<h3 key={type.__typename + index} className="blog-post-subheading">{item.subheading.text}</h3>);
                }
                
                if (item.text.text) {
                    if (item.text.text[0] === '"' && item.text.text[item.text.text.length-1] === '"') {
                        content.push(<div className="blog-post-quote" dangerouslySetInnerHTML={{ __html: item.text.text}}></div>);
                    } else {
                        content.push(<div className="blog-post-content" dangerouslySetInnerHTML={{ __html: item.text.text.trim()}}></div>);
                    }
                }
            });
            return content;
            break;
        case 'PrismicBlogPostBodyImages':
            return <img key={type.__typename + index} src={type.items[0].image.url} className="blog-post-image" />;
    }
};

const BlogPost = ({ pageContext: { post, posts } }) => {
    const relatedPosts = posts
        .filter(({ node: { data } }) => (data.post_type == post.post_type) && (data.title.text != post.title.text))
        .sort((a, b) => new Date(a.date) < new Date(b.date));

    return (
        <Layout>
            <SEO title={`${post.title.text} | Orton Blog - Where writers become authors`} description={post.description.text} author={bloggers[post.blogger]} />
            <div className="wrapper blog-post">
                <p className="blog-post-date">{format(new Date(post.date), 'Do MMMM YYYY')}</p>
                <p className="blog-post-type">{post.post_type}</p>
                <h1 className="blog-post-title">{post.title.text}</h1>
                {post.body.map((item, index) => renderContentType(item, index))}
                { post.blogger && <Blogger name={post.blogger} /> }
                <a href="https://my.orton.io/signup" className="button button--filled">Sign up</a>
                <Link to="/" className="button">Read more of our blog</Link>
                { relatedPosts.length > 0 && <div className="related-posts">
                    <h5>Related blog posts</h5>
                    { relatedPosts
                        .slice(0, 5)
                        .map(({ node }) => (
                            <div className="related-post" key={node.uid}>
                                <Link to={node.uid} className="link">{node.data.title.text}</Link>
                                <p>{format(new Date(node.data.date), 'Do MMMM YYYY')}</p>
                            </div>
                        )) }
                </div> }
            </div>
        </Layout>
    );
};

export default BlogPost;

// export const pageQuery = graphql`
//     query BlogPostBySlug($uid: String!) {
//         prismicBlogPost(uid: { eq: $uid }) {
//             uid
//             data {
//                 date
//                 title {
//                     text
//                 }
//                 body {
//                     items {
//                         text {
//                             text
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;
